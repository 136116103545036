import { computed, inject, Injectable, signal } from '@angular/core';
import { DEFAULT_APP_LOCALE } from '@constants/default-app-locale.const';
import { AppLocale } from '@type/app-locale';
import { tap, Observable, firstValueFrom, catchError, of } from 'rxjs';
import { ChangePasswordRequest, User } from '@type/user';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  protected http = inject(HttpClient);

  currentUser = signal<User | null>(null);
  locale = computed<AppLocale>(() => DEFAULT_APP_LOCALE);

  update() {
    return firstValueFrom(
      this.loadCurrentUser().pipe(
        tap((user) => {
          this.currentUser.set(user);
        }),
      ),
    );
  }

  initialize() {
    return firstValueFrom(
      this.loadCurrentUser().pipe(
        tap((user) => {
          this.currentUser.set(user);
        }),
        catchError(() => of(null)),
      ),
    );
  }

  protected loadCurrentUser() {
    return this.http.get<User>('admin/users/current_user');
  }

  changePassword(value: ChangePasswordRequest): Observable<Record<string, never>> {
    return this.http.put<Record<string, never>>('api/users/change_password', value);
  }
}
